import { AxiosError } from "axios";
import { createContext, useEffect, useReducer,useCallback,useMemo } from "react";
import { useQueryClient } from "react-query";
import api, { axiosClient, ApiError } from "../api";
import { StringOrNumber } from "../types/common";
import { ErrorAlert } from "../utils/alerts";

type ContextType = {
  authState: State;
  saveLoggedInUserData: (data: any) => void;
  removeLoggedOutUserData: (data: any) => void;
};

export const AuthContext = createContext<ContextType>({} as ContextType);

type State = {
  data?:{
    role:string,
    baCode:StringOrNumber,
  }
  token: string;
};
type ActionType = {
  type: string;
  payload?: any;
};

const initialState: State = {
  token:getTokenFromLocalStorage()||"",
};

function reducer(state: State, action: ActionType) {
  switch (action.type) {
    case "login":
      return {
        ...state,
        ...action.payload,
      };
    case "logout":
      return {};
    default:
      return state;
  }
}

function saveTokenInLocalStorage(token:string){
  localStorage.setItem("token",token);
}
function getTokenFromLocalStorage(){
  return localStorage.getItem("token");
}
function deleteTokenInLocalStorage(){
  localStorage.removeItem("token")
}

export default function AuthProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const [authState, dispatch] = useReducer(reducer, initialState);
  const queryClient=useQueryClient();
  const saveLoggedInUserData = useCallback((data: any,isNew:boolean=true) => {
    dispatch({ type: "login", payload: data });
    if(isNew){
      saveTokenInLocalStorage(data.token)
    }
  }, []);

  const removeLoggedOutUserData = useCallback(() => {
    dispatch({ type: "logout" });
    queryClient.clear();
    deleteTokenInLocalStorage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    // alert("auth running...")
    const token=getTokenFromLocalStorage()
    if(token&&!authState.data){
      api.post("/user/get-user",{},{headers:{token:token}}).then(res=>{
        console.log(res.data)
        saveLoggedInUserData({data:res.data,token:token})
      })
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps
  useMemo(() => {
    axiosClient.interceptors.request.use(
      function (config) {
        console.log(
          "%c axios interceptor from auth",
          "color:green;font-size:24px;font-weight:bold"
        );
        if (!navigator.onLine) {
          throw "NOINTERNET"; // eslint-disable-line 
        }
        const token =getTokenFromLocalStorage();
        if (token) {
          config.headers!["token"] = token;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

   axiosClient.interceptors.response.use(
      (e) => {
        // ErrorAlert("Server Error");
        // SuccessAlert("create")
        return e;
      },
      (error: AxiosError<ApiError> | string) => {
        if (typeof error === "string") {
          if (error === "NOINTERNET") {
            ErrorAlert("No Internet Connection");
          }
        } else {
          const res = error.response;
          const status = res!.status || undefined;
          if (status !== undefined) {
            if (status >= 500) {
              ErrorAlert("Server Error");
            }
            else if (status === 401) {
              ErrorAlert("Unauthorized");
              // navigate("/login");
            }
            else if (status >= 400 && status < 500) {
              if (typeof res?.data.message === "string") {
                ErrorAlert(res?.data.message || "Server Error");
              }
            }
          }
        }
        return Promise.reject(error);
      }
    );
  },[])

  // useEffect(() => {
  //   axiosClient.interceptors.request.use(
  //     function (config) {
  //       console.log(
  //         "%c axios interceptor from auth",
  //         "color:green;font-size:24px;font-weight:bold"
  //       );
  //       if (!navigator.onLine) {
  //         throw "NOINTERNET";
  //       }
  //       const token =getTokenFromLocalStorage();
  //       if (token) {
  //         config.headers!["token"] = token;
  //       }
  //       return config;
  //     },
  //     function (error) {
  //       return Promise.reject(error);
  //     }
  //   );

  //  axiosClient.interceptors.response.use(
  //     (e) => {
  //       return e;
  //     },
  //     (error: AxiosError<ApiError> | string) => {
  //       if (typeof error === "string") {
  //         if (error === "NOINTERNET") {
  //           ErrorAlert("No Internet Connection");
  //         }
  //       } else {
  //         const res = error.response;
  //         const status = res!.status || undefined;
  //         if (status !== undefined) {
  //           if (status >= 500) {
  //             ErrorAlert("Server Error");
  //           }
  //           if (status === 401) {
  //             ErrorAlert("Unauthorized");
  //             // navigate("/login");
  //           } else if (status >= 400 && status < 500) {
  //             if (typeof res?.data.message === "string") {
  //               ErrorAlert(res?.data.message || "Server Error");
  //             }
  //           }
  //         }
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  // },[]);

  return (
    <AuthContext.Provider
      value={{ authState, saveLoggedInUserData, removeLoggedOutUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
}

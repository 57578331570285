import axios, { AxiosInstance, AxiosRequestConfig,AxiosError,AxiosResponse} from "axios";

export const axiosClient:AxiosInstance=axios.create({
    baseURL:process.env.REACT_APP_BACKEND_BASEURL,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
      }
});

// axiosClient.interceptors.request.use(function (config:AxiosRequestConfig<any>) {
//   if(!navigator.onLine){
//     throw "NOINTERNET";
//   }
//   const token=localStorage.getItem("token");
//     if (token) {
//         config.headers!['token'] =token;
//     }
//     return config;
//   }, function (error) {
//     return Promise.reject(error);
//   })

//   axiosClient.interceptors.response.use((e)=>{return e},(error:AxiosError<ApiError>|string)=>{
//     if(typeof error==="string"){
//       if(error==="NOINTERNET"){
//         ErrorAlert("No Internet Connection")
//       }
//     }
//     else{
//       const res=error.response;
//       const status=res!.status||undefined;
//       if(status!==undefined){
//         if(status>=500){
//           ErrorAlert("Server Error");
//         }
//         if(status===401){
//           ErrorAlert("Unauthorized")
//         }
//         else if(status>=400&&status<500){
//           if(typeof(res?.data.message)==="string"){
//             ErrorAlert(res?.data.message||"Server Error")
//           }
//         }
//       }
//     }
//     return Promise.reject(error)
//   })

export type DataObject= {
    [key:string]:any;
}

export interface ApiResponse<T>{
    data:T;
    status:string;
}

export interface ApiError{
  status:string;
  statusCode:number;
  message:DataObject|string;
}

export class Api{
  async get<DataFormat=any>(url:string,config?:AxiosRequestConfig<ApiResponse<DataFormat>>){
       return axiosClient.get<ApiResponse<DataFormat>>(url,config)
       .then(res=>res.data)
       .catch((e:AxiosError<ApiError>)=>{throw e.response?.data})
  }
  async post<payloadData=any,DataFormat=any>(url:string,data?:payloadData,config?:AxiosRequestConfig<payloadData>){
    return axiosClient.post<payloadData,AxiosResponse<ApiResponse<DataFormat>>>(url,data,config)
    .then(res=>res.data)
    .catch((e:AxiosError<ApiError>)=>{throw e.response?.data})
  }
  async put<payloadData=any,DataFormat=any>(url:string,data?:payloadData,config?:AxiosRequestConfig<payloadData>){
    return axiosClient.put<payloadData,AxiosResponse<ApiResponse<DataFormat>>>(url,data,config)
    .then(res=>res.data)
    .catch((e:AxiosError<ApiError>)=>{throw e.response?.data})
  }
  async delete<payloadData=any,DataFormat=any>(url:string,config?:AxiosRequestConfig<payloadData>){
    return axiosClient.delete<payloadData,AxiosResponse<ApiResponse<DataFormat>>>(url,{...config})
    .then(res=>res.data)
    .catch((e:AxiosError<ApiError>)=>{throw e.response?.data})
  }
}

const api=new Api();
Object.freeze(api);

export default api;
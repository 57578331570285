import React, { useEffect } from "react"
import { ThemeProvider as MaterialThemeProvider,useTheme,createTheme } from "@mui/material/styles";

interface Props{
  children:React.ReactNode;
}

type ThemeContextType={
  font:string;
  setFont:React.Dispatch<React.SetStateAction<string>>
}

export const ThemeContext= React.createContext<ThemeContextType>({} as ThemeContextType);

export default function ThemeProvider(props:Props) {
    const theme=useTheme();
    const mainFontFamily=['Roboto','"Helvetica Neue"','Arial','sans-serif'];
    // const fontFamilyArray=["Heebo","Calibri",'Tahoma','"Segoe UI"','Roboto','"Helvetica Neue"','Arial','sans-serif']
    const [fontFamilyArray,setFontFamily]=React.useState<string[]>([]);
    const [font,setFont]=React.useState("Calibri");

    useEffect(()=>{
      setFontFamily([font,...mainFontFamily]);
    },[font]) //eslint-disable-line react-hooks/exhaustive-deps

    const customTheme=createTheme({
        typography:{
          fontFamily:fontFamilyArray.join(","),
          appBarTitle:{
            fontFamily:fontFamilyArray.join(","),
            fontSize:theme.typography.pxToRem(18),
            fontWeight:600,
            lineHeight:1.33,
            letterSpacing:"normal",
            color:"#000"
            // color:"#0e0e0e",
          },
          body1:{
            ...theme.typography.body1,
            fontFamily:fontFamilyArray.join(","),
            fontSize:theme.typography.pxToRem(14.5),
            fontWeight:400,
            letterSpacing:"normal",
          },
          title:{
            fontFamily:fontFamilyArray.join(","),
            fontSize:theme.typography.pxToRem(16),
            fontWeight:600,
            lineHeight:1.35,
            letterSpacing:"normal",
            color:"#000",
          },
          subtitle:{
            fontFamily:fontFamilyArray.join(","),
            fontSize:theme.typography.pxToRem(14.5),
            fontWeight:600,
            lineHeight:1.29,
            letterSpacing:"normal",
            color:"#000",
          },
          label:{
            fontFamily:fontFamilyArray.join(","),
            fontSize:theme.typography.pxToRem(14.5),
            fontWeight:600,
            lineHeight:1.33,
            letterSpacing:"normal",
            color:"#000"
            // color:alpha("#000",0.8) ,
          },
          dialogTitle:{
            fontFamily:fontFamilyArray.join(","),
            fontSize:theme.typography.pxToRem(29),
            fontWeight:900,
            lineHeight:1.34,
            letterSpacing:"normal",
            color:"#272d3b" ,
          }
        },
        palette:{
          primary:{
            // main:"#3d58c1" // #5471e2
            main:"#1635ae",
            light:"#3d58c1"
          },
          black:{
            main:"#000",
            contrastText:"#fff"
          },
          lightBlack:{
            main:"#606060",
            contrastText:"#fff"
          },
          error:{
            main:"#fe5656"
          },
          success:{
            main:"#54cca7"
          }
        },
        mixins:{
          mediumToolbar:{
            minHeight: 56,
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
              minHeight:56
            },
            [theme.breakpoints.up('sm')]: {
              minHeight:56
            }
          }
        }
      })
    return (
      <>
      <ThemeContext.Provider value={{font,setFont}}>
        <MaterialThemeProvider theme={customTheme}>
            {props.children}
        </MaterialThemeProvider>
      </ThemeContext.Provider>
      </>
    )
}

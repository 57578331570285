import Swalert, { SweetAlertOptions } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./alert.module.css";
// import ErrorIcon from '@mui/icons-material/Error';

type RequiredField<T, K extends keyof T> = T & Required<Pick<T, K>>;

type SwalInfoOptions=RequiredField<SweetAlertOptions,"title"|"cancelButtonText"|"confirmButtonText">

const Swal=withReactContent(Swalert);

export function SwalInfo({customClass={},...options}:SwalInfoOptions){
 return Swal.fire({
    // toast:true,
    icon: 'info',
    showCancelButton:true,
    showConfirmButton:true,
    confirmButtonColor:"#3d58c1",
    backdrop:true,
    allowEscapeKey:false,
    allowOutsideClick:false,
    didOpen:(popup)=>{
      const confirmNode= popup.getElementsByClassName("swal2-confirm")[0] as HTMLButtonElement;
      confirmNode.focus();
     },
    cancelButtonColor:"rgba(0, 0, 0, 0.23)",
    customClass:{
      // icon:styles.icon,
      // title:styles.title,
      // popup:styles.popup,
      // htmlContainer:styles.htmlContainer,
      actions:styles.actions,
      ...customClass as any
    },
    ...options
  })
}

export const DeleteAlert=(data?:string|number,onDelete?:()=>void)=>{
  let title=data||"Do you want to delete this record?";
    SwalInfo({
      title,
      cancelButtonText:"Cancel",
      confirmButtonText:"Delete",
      confirmButtonColor:"#fe5656",
      customClass:{
        confirmButton:styles.deleteConfirmButton
      }
    })
    .then(result=>{
      if(result.isConfirmed){
       onDelete&&onDelete();
      }
    })
  }
  export const RemoveAlert=(data:string|number,onDelete:()=>void)=>{
    let title=data||"Do you want to Remove this record?";
      SwalInfo({
        title,
        cancelButtonText:"Cancel",
        confirmButtonText:"Remove",
        confirmButtonColor:"#fe5656",
        customClass:{
          confirmButton:styles.deleteConfirmButton
        }
      })
      .then(result=>{
        if(result.isConfirmed){
         onDelete();
        }
      })
    }
    type props={
      title:string;
      onConfirm?:()=>void;
      confirmText?:string;
      cancelText?:string;
    }
    export const AlertWithConfirmAndCancel=({title,onConfirm,...args}:props)=>{
        SwalInfo({
          title,
          cancelButtonText:args.cancelText||"Cancel",
          confirmButtonText:args.confirmText||"Delete",
          confirmButtonColor:"#fe5656",
          customClass:{
            confirmButton:styles.deleteConfirmButton
          }
        })
        .then(result=>{
          if(result.isConfirmed){
           onConfirm&&onConfirm();
          }
        })
      }
  export const SuccessAlert=(successType:"create"|"update"|"delete",onSuccess?:()=>void,timer?:number)=>{
    Swal.fire({
      // toast:true,
      text: `${successType==="update"?"Updated":successType==="create"?"Created":"Deleted"} Successfully`,
      icon: 'success',
      backdrop:false,
      timer:timer||1000,
      showConfirmButton:false,
      showCancelButton:false,
      customClass:{
        // icon:styles.icon,
        // title:styles.title,
        // popup:styles.popup,
        // htmlContainer:styles.htmlContainer,
        actions:styles.actions
      }
    })
    .then(result=>{
      if(onSuccess)onSuccess();
    })
  }

  export const ErrorAlert=(error:string,onConfirmed?:()=>void,options?:SweetAlertOptions)=>{
    Swal.fire({
      text:error,
      // toast:true,
      icon:"error",
      iconColor:"#fe5656",
      confirmButtonColor:"#3d58c1",
      showConfirmButton:true,
      backdrop:true,
      focusConfirm:true,
      showCancelButton:false,
      allowOutsideClick:false,
      allowEscapeKey:false,
      customClass:{
        actions:styles.actions,
        // container:styles.container,
        // popup:styles.popup,
        // htmlContainer:styles.htmlContainer,
        // icon:styles.icon,
        // title:styles.title,
        
      },
      ...options
    }).then((value)=>{
      if(value.isConfirmed){
        onConfirmed&&onConfirmed();
      }
    })
  }
  
  export const ErrorAlert2=Swal.mixin({
      icon:"error",
      iconColor:"#fe5656",
      confirmButtonColor:"#3d58c1",
      showConfirmButton:true,
      backdrop:true,
      focusConfirm:true,
      showCancelButton:true,
      cancelButtonText:"Remove",
      cancelButtonColor:"#fe5656",
      allowOutsideClick:false,
      allowEscapeKey:false,
      customClass:{
        actions:styles.actions,
      },
  })

  export const WarningAlert=(error:string,onConfirmed?:()=>void)=>{
    Swal.fire({
      text:error,
      // toast:true,
      backdrop:true,
      icon:"warning",
      iconColor:"#fe5656",
      confirmButtonColor:"#3d58c1",
      showConfirmButton:true,
      focusConfirm:true,
      showCancelButton:false,
      allowOutsideClick:false,
      allowEscapeKey:false,
      customClass:{
        // icon:styles.icon,
        // title:styles.title,
        // popup:styles.popup,
        // htmlContainer:styles.htmlContainer,
        actions:styles.actions
      }
    }).then((value)=>{
      if(value.isConfirmed){
        onConfirmed&&onConfirmed();
      }
    })
  }
import {createRoot} from 'react-dom/client';
import './index.css';
import "react-base-table/styles.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const container=document.getElementById('root')!;
const root=createRoot(container);
if (module.hot) {
    module.hot.accept();
  }
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

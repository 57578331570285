import { Routes, Route, Navigate} from "react-router-dom";
import useAuth  from "../hooks/useAuth";
import React from "react";

const Inventory=React.lazy(()=>import("./Inventory"))
const LoginView=React.lazy(()=>import("../Views/Login"))


interface Props{

}

  
export default function Routers(props:Props) {
    const {authState}=useAuth();
    const loggedIn=authState.token;
    console.log("%c authState","color:red;font-size:24px",authState)
    return (
       
       <Routes>
           <Route path="/" element={<Navigate to={loggedIn?"/inventory":"/login"}/>}/>
           {
               loggedIn?
               <Route path="inventory/*" element={ <React.Suspense fallback><Inventory/></React.Suspense>}/>
               :
                <Route path="/login" element={<React.Suspense fallback><LoginView/></React.Suspense>}/>
           }
           <Route path="*" element={<Navigate to={"/"} replace/>}/>
       </Routes> 
    )
}

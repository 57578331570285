import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import CustomThemeProvider from "./context/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routes";
import AuthProvider from "./context/Auth";
import React from "react";
import InteractionEvent from "./components/InteractionEvent";

export const AppVersion="V 1.13.7";

function App() {
  
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 30000,
      },
    },
  });

  return (
    <>
    <InteractionEvent>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ThemeProvider theme={{}}>
              <CustomThemeProvider>
                <Routers />
              </CustomThemeProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen />
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
      </InteractionEvent>
    </>
  );
}

export default App;
